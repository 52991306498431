import { postToWebtidApi } from 'utils/http';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { getTranslatedOrFallback } from 'utils/translation';
import useAuth from './useAuth';
import {
    StringMap,
    UseFetchArrayOptions,
    UseFetchArrayResult,
    UseFetchOptions,
    UseFetchResult
} from './useFetch.types';
import useMemoWithIsEqual from './useMemoWithIsEqual';

/**
 * Fetch data from the WebTid API
 * @param url The API URI
 * @param optionsParam
 * @returns The data as an array, a loading state, any errors and a function to repeat the request
 */
export function useFetchArray<T = unknown, Input extends object = StringMap>(
    url: string,
    optionsParam?: UseFetchArrayOptions<T, Input>
): UseFetchArrayResult<T> {
    const automaticallyTriggerRequest = !optionsParam?.manuallyTriggerRequest;

    const [data, setData] = useState<Array<T>>([]);
    const [isLoading, setIsLoading] = useState(automaticallyTriggerRequest);
    const [hasReceivedResponse, setHasReceivedResponse] = useState(false);
    const [errorMessage, setError] = useState<string>('');
    const opt = useMemo(
        () => ({
            reqData: {},
            isAnonymous: false,
            isGet: false,
            manuallyTriggerRequest: false,
            ignoreErrors: false,
            ...optionsParam
        }),
        [optionsParam]
    );
    const memoizedRequestData = useMemoWithIsEqual(opt.reqData);

    const { onAuthError } = useAuth();

    useEffect(() => {
        setData([]);
    }, [memoizedRequestData]);

    const sendRequest = useCallback(async () => {
        setIsLoading(true);
        setError('');

        try {
            const response = await postToWebtidApi<Array<T>>(url, opt.reqData, {
                isAnonymous: opt.isAnonymous,
                method: opt.isGet ? 'GET' : 'POST',
                onAuthError: opt.ignoreErrors ? () => {} : onAuthError
            });

            if (response.success) {
                setData(response.content);

                if (opt.onSuccess) {
                    opt.onSuccess(response.content);
                }
            } else {
                const { displayErrorMessage } = response;
                const translatedOrUntouchedErrorMessage =
                    getTranslatedOrFallback(displayErrorMessage);

                setError(translatedOrUntouchedErrorMessage);

                if (opt.onErrorMessage) {
                    opt.onErrorMessage(displayErrorMessage);
                }
            }
        } catch (err) {
            if (!opt.ignoreErrors) {
                setError(`${err}`);
            }
        } finally {
            setIsLoading(false);
            setHasReceivedResponse(true);
        }
    }, [onAuthError, opt, url]);

    useEffect(() => {
        if (automaticallyTriggerRequest) {
            sendRequest();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [url, memoizedRequestData, automaticallyTriggerRequest]);

    return [
        data as Array<T>,
        {
            isLoading,
            isLoadingFirstTime: isLoading && !hasReceivedResponse,
            errorMessage,
            sendRequest
        }
    ];
}

/**
 * Performs an authenticated API request
 * @param url The API URI
 * @param options
 * @returns The data as an object, a loading state, any errors and a function to repeat the request
 */
export function useFetch<Payload = unknown, Input extends object = StringMap>(
    url: string,
    options?: UseFetchOptions<Payload, Input>
): UseFetchResult<Payload> {
    const memoizedOptions = useMemo(
        () => ({
            ...options,
            onSuccess: options?.onSuccess
                ? (result: Payload[]) =>
                      options?.onSuccess ? options.onSuccess(result[0]) : undefined
                : undefined
        }),
        [options]
    );

    const [data, rest] = useFetchArray<Payload, Input>(url, memoizedOptions);

    return [data[0] as Payload, rest];
}
