import { Grid, Typography } from '@mui/material';
import HFCheckboxGroup from 'components/hookForm/HFCheckboxGroup';
import HFFormProvider from 'ctx/HFFormProvider';
import { useFetch } from 'hooks/useFetch';
import { DutyWishFilterPossibilitiesResult } from 'models/DutyWishFilter';
import HFCheckbox from 'components/hookForm/HFCheckbox';
import { useTranslation } from 'react-i18next';
import ActionButton from 'components/button/ActionButton';
import ErrorMessage from 'components/error/ErrorMessage';
import SuccessMessage from 'components/form/SuccessMessage';
import { useCallback, useEffect, useState } from 'react';
import InlineLoadingSpinner from 'components/loading/InlineLoadingSpinner';
import useThrottleStartStopFn from 'hooks/useThrottleStartStopFns';
import useDutyWishFilterForm from './useDutyWishFilterForm/useDutyWishFilterForm';

export default function DutyFilterMain() {
    const [filterValues, { isLoading }] = useFetch<DutyWishFilterPossibilitiesResult>(
        'getPossibleValuesForDutyWishFilter'
    );
    const [displaySuccess, setDisplaySuccess] = useState(false);

    const formProps = useDutyWishFilterForm(() => {
        setDisplaySuccess(false);
        return true;
    });

    const { t } = useTranslation();
    const { isSubmitSuccessful } = formProps.formState;

    // Reset form and init display success message
    const { reset: resetForm } = formProps;
    useEffect(() => {
        if (isSubmitSuccessful) {
            resetForm();
            setDisplaySuccess(true);
        }
    }, [isSubmitSuccessful, resetForm]);

    // Clear success after 4 seconds, or if user submits again
    const { run, flush } = useThrottleStartStopFn({
        runAtStart: useCallback(() => setDisplaySuccess(true), []),
        runAtStop: useCallback(() => setDisplaySuccess(false), []),
        runTime: 4000
    });
    useEffect(() => {
        if (displaySuccess) run();
        else if (!isSubmitSuccessful) flush(); // The !isSubmitSuccessful avoids flush when displaySuccess is about to be set to true, which would revert it to false in the same tick.
    }, [isSubmitSuccessful, displaySuccess, run, flush]);

    if (isLoading) {
        return <InlineLoadingSpinner />;
    }

    return (
        <HFFormProvider formProps={formProps}>
            <Grid container spacing={2} sx={{ pt: 1 }}>
                <Grid item xs={12}>
                    <Typography>{t('dutyWishFilterSetup.helperText')}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <HFCheckboxGroup
                        listSx={{ height: 385, position: 'relative', overflow: 'auto' }}
                        name="dutyTypeIds"
                        title={t('dutyWishFilterSetup.availableDutyTypes')}
                        items={
                            filterValues?.dutyTypes.map((dutyType) => ({
                                label: dutyType.description,
                                value: dutyType.id
                            })) ?? []
                        }
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <HFCheckboxGroup
                        listSx={{ height: 385, position: 'relative', overflow: 'auto' }}
                        name="departmentIds"
                        title={t('dutyWishFilterSetup.availableDepartments')}
                        items={
                            filterValues?.departments.map((department) => ({
                                label: `${department.id} ${department.description}`,
                                value: department.id
                            })) ?? []
                        }
                    />
                </Grid>
                <Grid item xs={12}>
                    <HFCheckbox
                        name="displayAvaliableDutiesOnDaysIAlreadyHaveOtherDuties"
                        label={t(
                            'dutyWishFilterSetup.toggleDisplayAvaliableDutiesOnDaysIAlreadyHaveOtherDuties'
                        )}
                    />
                </Grid>
                <Grid item xs={12}>
                    {formProps.displayErrorMessage && (
                        <ErrorMessage severity="error" message={formProps.displayErrorMessage} />
                    )}
                    {displaySuccess && (
                        <SuccessMessage sx={{ mb: 2 }}>
                            {t('dutyWishFilterSetup.successfulSave') || ''}
                        </SuccessMessage>
                    )}
                    <ActionButton type="submit" isloading={formProps.formState.isSubmitting}>
                        {t('actions.save')}
                    </ActionButton>
                </Grid>
            </Grid>
        </HFFormProvider>
    );
}
