import { i18n } from 'appI18n';
import useFormWithApiIntegration from 'hooks/useFormWithApiIntegration';
import { EmployeeChild } from 'models/EmployeeChild';
import { useEffect } from 'react';
import customValidators from 'utils/customValidators';
import { dateToIsoString } from 'utils/datetime';
import { boolean, number, object, string, z } from 'zod';

function getEmployeeChildRegistrationSchema() {
    return object({
        id: number().optional(),
        name: string().min(1, i18n.t('pleaseFill') || ''),
        birthDate: customValidators.date({
            maxDateInIsoFormat: dateToIsoString(new Date()),
            errorTextMaxDateBreach: i18n.t('dateTime.errors.cannotBeFutureDate') || ''
        }),
        gender: string(),
        livesWithMe: boolean(),
        soleCustody: boolean(),
        isChronicallyIll: boolean(),
        isStepChild: boolean(),
        active: boolean()
    }).superRefine(({ soleCustody, livesWithMe }, ctx) => {
        if (soleCustody && !livesWithMe) {
            ctx.addIssue({
                code: 'custom',
                message:
                    i18n.t(
                        'profile.employeeChildren.validationErrors.livesWithMeRequiredForSoleCustody'
                    ) || '',
                path: ['soleCustody']
            });
        }
    });
}

export type PostEmployeeChildRegistrationPayload = undefined;
export type EmployeeChildRegistrationFormData = z.infer<
    ReturnType<typeof getEmployeeChildRegistrationSchema>
>;

function getDefaultValues(
    employeeChild?: EmployeeChild | undefined
): Partial<EmployeeChildRegistrationFormData> {
    const pickerFriendlyBirthDate = employeeChild?.dateOfBirth
        ? dateToIsoString(new Date(employeeChild.dateOfBirth))
        : undefined;

    return {
        ...(employeeChild && { id: employeeChild.id }),
        name: employeeChild?.name || '',
        birthDate: pickerFriendlyBirthDate,
        gender: employeeChild?.gender || '',
        livesWithMe: employeeChild?.livesWithMe || false,
        soleCustody: employeeChild?.soleCustody || false,
        isChronicallyIll: employeeChild?.isChronicallyIll || false,
        isStepChild: employeeChild?.isStepChild || false,
        active: employeeChild?.active ?? true
    };
}

export default function useEmployeeChildRegistrationForm(
    employeeChild: EmployeeChild | undefined,
    onSuccess?: VoidFunction
) {
    const employeeChildRegistrationSchema = getEmployeeChildRegistrationSchema();
    const defaultValues = getDefaultValues(employeeChild);

    const formSetup = useFormWithApiIntegration<PostEmployeeChildRegistrationPayload>(
        '/upsertChild',
        employeeChildRegistrationSchema,
        defaultValues,
        { onSuccess }
    );

    const {
        watch,
        trigger,
        formState: { isSubmitted }
    } = formSetup;

    useEffect(() => {
        const subscription = watch((value, { name }) => {
            if (name === 'livesWithMe' && isSubmitted) {
                trigger('soleCustody');
            }
        });

        return () => subscription.unsubscribe();
    }, [watch, trigger, isSubmitted]);

    return formSetup;
}
