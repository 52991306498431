import { Fragment, useMemo } from 'react';
import { Link } from 'react-router-dom';

interface MarkdownLinkRendererProps {
    text: string;
}

export default function MarkdownLinkRenderer({ text }: MarkdownLinkRendererProps) {
    return useMemo((): JSX.Element => {
        // Regular expression to match markdown links: [text](url)
        const linkRegex = /\[([^\]]+)\]\(([^)]+)\)/g;

        // Split the text into parts (text and links)
        const parts: React.ReactNode[] = [];
        let lastIndex = 0;
        let match = linkRegex.exec(text);
        let linkCounter = 0;

        while (match !== null) {
            // Add text before the link
            if (match.index > lastIndex) {
                parts.push(text.slice(lastIndex, match.index));
            }

            // Add the link component
            const [, linkText, linkUrl] = match;
            parts.push(
                <Link key={`${match.index}-${linkText}-${linkCounter}`} to={linkUrl}>
                    {linkText}
                </Link>
            );
            linkCounter += 1;

            lastIndex = match.index + match[0].length;
            match = linkRegex.exec(text);
        }

        // Add remaining text after the last link
        if (lastIndex < text.length) {
            parts.push(text.slice(lastIndex));
        }

        return <Fragment key="string-with-link">{parts}</Fragment>;
    }, [text]);
}
