export enum AbsenceCodeType {
    Absence = 1,
    TimeOff = 2,
    Vacation = 3,
    Leave = 4,
    LaidOff = 5
}

export enum AbsenceCodeCertificationType {
    NoCertification = 0,
    SelfSertified = 1,
    SelfCertifiedChildSickness = 2,
    DoctorCertifiedSickness = 3,
    Leave = 4,
    LayOff = 5
}

export type AbsenceCode = {
    absenceCode: string;
    description: string;
    type: AbsenceCodeType;
    certificationType: AbsenceCodeCertificationType;
    isSelfCertificationAllowed: boolean;
};
