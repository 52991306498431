import { Person } from '@mui/icons-material';
import { Avatar, AvatarProps } from '@mui/material';

export default function EmployeeChildAvatar({ sx }: AvatarProps) {
    return (
        <Avatar sx={sx}>
            <Person />
        </Avatar>
    );
}
