import { useDimensionOptionsSet } from 'features/misc/dimensionInput';
import { useMemo, useState } from 'react';
import AlertDialog from 'components/dialog/AlertDialog';
import { useTranslation } from 'react-i18next';
import AbsenceRequestRegistrationView from './AbsenceRequestRegistrationView';
import useAbsenceRequestForm from './hooks/useAbsenceRequestForm';
import { useAbsenceDimensionInputRules, useAbsenceCodeOptions } from '../hooks';
import { pickDimensionValuesFromDefaultValues } from './AbsenceRequestRegistration.helpers';
import { AbsenceRequestRegistrationProps } from './AbsenceRequestRegistration.types';

export default function AbsenceRequestRegistration({
    date,
    onCancel,
    onSuccess,
    absenceRequest,
    sx
}: AbsenceRequestRegistrationProps) {
    const { dimensionInputRules } = useAbsenceDimensionInputRules(date, absenceRequest);

    const {
        options: absenceCodeOptions,
        isLoading: isLoadingAbsenceCodeOptions,
        errorMessage
    } = useAbsenceCodeOptions();

    const {
        defaultValues,
        isReady: isFormSetupReady,
        ...formProps
    } = useAbsenceRequestForm(date, dimensionInputRules, absenceRequest, onSuccess);

    const initDimensionValuesByDimensionName = useMemo(() => {
        const dimensionValuesFromDefault = pickDimensionValuesFromDefaultValues(defaultValues);
        return dimensionValuesFromDefault;
    }, [defaultValues]);

    const dimensionValuesIgnoringValueChangesAfterInit = initDimensionValuesByDimensionName; // Providing up-to-date values are only necessary when there's a possibility for dependecies across dimensions (ie Project<-->SubProject). For now we know this is not the case and we don't set aside time for keeping the value up to date.
    const { dimensionOptionsSet, isDescriptionsInitialized, dimensionNamesLoading } =
        useDimensionOptionsSet(
            dimensionInputRules,
            dimensionValuesIgnoringValueChangesAfterInit,
            initDimensionValuesByDimensionName
        );

    const dimInputRules = useMemo(() => dimensionInputRules || [], [dimensionInputRules]);

    const { t } = useTranslation();
    const [hasError, setHasError] = useState(errorMessage !== undefined);

    if (errorMessage && !isLoadingAbsenceCodeOptions) {
        return (
            <AlertDialog
                errorMessage={errorMessage}
                onConfirm={() => {
                    setHasError(false);
                    onCancel(); // Close the dialog opened by the parent
                }}
                title={t('error')}
                open={hasError}
                isRunningConfirmOperation={false}
                severity="error"
            />
        );
    }

    return (
        <AbsenceRequestRegistrationView
            isLoading={
                isLoadingAbsenceCodeOptions || !isDescriptionsInitialized || !isFormSetupReady
            }
            formProps={formProps}
            onCancel={onCancel}
            sx={sx}
            absenceCodeOptions={absenceCodeOptions}
            isLoadingAbsenceCodeOptions={isLoadingAbsenceCodeOptions}
            dimensionInputRules={dimInputRules}
            dimensionOptionsSet={dimensionOptionsSet}
            dimensionNamesLoading={dimensionNamesLoading}
            initDimensionValuesByDimensionName={initDimensionValuesByDimensionName}
        />
    );
}

AbsenceRequestRegistration.defaultProps = {
    sx: undefined,
    absenceRequest: undefined
};
