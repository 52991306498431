import { Controller, Path, useFormContext } from 'react-hook-form';
import {
    Checkbox,
    CheckboxProps,
    FormControl,
    FormControlLabel,
    FormControlLabelProps,
    FormGroup,
    FormHelperText
} from '@mui/material';
import { FieldValues } from 'react-hook-form/dist/types/fields';
import useFieldNameGuard from 'hooks/useFieldNameGuard';

export type HFCheckboxProps<T extends FieldValues> = Omit<CheckboxProps, 'name'> & {
    name: Path<T>;
    label: FormControlLabelProps['label'];
    helperText?: string;
    indentHelperText?: boolean;
    keepHelperTextOnError?: boolean;
};

export default function CheckboxElement<TFieldValues extends FieldValues>({
    required,
    label,
    helperText,
    indentHelperText,
    keepHelperTextOnError,
    ...checkboxProps
}: HFCheckboxProps<TFieldValues>) {
    const context = useFormContext();
    const { control } = context;

    const { name } = checkboxProps;
    useFieldNameGuard(name, context);

    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
                const invalid = Boolean(error);
                const errorMessage = error?.message || '';

                return (
                    <FormControl
                        component="fieldset"
                        variant="standard"
                        required={required}
                        error={invalid}
                    >
                        <FormGroup row>
                            <FormControlLabel
                                color={invalid ? 'error.main' : undefined}
                                label={label || ''}
                                control={
                                    <Checkbox
                                        {...checkboxProps}
                                        color={checkboxProps.color || 'primary'}
                                        sx={{
                                            ...checkboxProps.sx,
                                            color: invalid ? 'error.main' : undefined
                                        }}
                                        value={value}
                                        checked={!!value}
                                        onChange={() => {
                                            onChange(!value);
                                        }}
                                    />
                                }
                            />
                        </FormGroup>
                        {errorMessage && invalid && (
                            <FormHelperText sx={{ ...(indentHelperText && { ml: 4 }) }} error>
                                {errorMessage}
                            </FormHelperText>
                        )}
                        {helperText && (!invalid || keepHelperTextOnError) && (
                            <FormHelperText
                                sx={{
                                    ...(indentHelperText && { ml: 4 })
                                }}
                                error={false}
                            >
                                {helperText}
                            </FormHelperText>
                        )}
                    </FormControl>
                );
            }}
        />
    );
}
CheckboxElement.defaultProps = {
    helperText: '',
    indentHelperText: false,
    keepHelperTextOnError: false
};
