import { ListItemAvatar, ListItemButton, ListItemText } from '@mui/material';
import { Link } from 'react-router-dom';
import { EmployeeChild } from 'models/EmployeeChild';
import EmployeeChildAvatar from './EmployeeChildAvatar';
import useRelativeAge from './useRelativeAge';

type EmployeeChildrenListItemProps = {
    child: EmployeeChild;
    isSelected: boolean;
};

export default function EmployeeChildrenListItem({
    child,
    isSelected
}: EmployeeChildrenListItemProps) {
    const ageText = useRelativeAge(child.dateOfBirth);

    return (
        <ListItemButton
            disableGutters={false}
            component={Link}
            to={`/profile?tab=employee-children&childId=${child.id}`}
            sx={(theme) => ({
                '&:hover': {
                    backgroundColor: isSelected ? theme.palette.primary.main : undefined
                },
                backgroundColor: isSelected ? theme.palette.primary.main : undefined
            })}
        >
            <ListItemAvatar>
                <EmployeeChildAvatar />
            </ListItemAvatar>
            <ListItemText
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between'
                }}
                primary={child.name}
                primaryTypographyProps={{
                    color: isSelected ? 'white' : undefined,
                    fontSize: '1em'
                }}
                secondary={ageText}
                secondaryTypographyProps={{
                    color: isSelected ? 'white' : undefined,
                    fontSize: '1em'
                }}
            />
        </ListItemButton>
    );
}
