import ConfirmDialog from 'components/dialog/ConfirmDialog';
import { useFetch } from 'hooks/useFetch';
import { EmployeeChild } from 'models/EmployeeChild';
import { useTranslation } from 'react-i18next';

type EmployeeChildDeleteDialogProps = {
    employeeChild: EmployeeChild;
    onSuccess: VoidFunction;
    onCancel: VoidFunction;
};

type PostDeleteEmployeeChildPayload = [];
type PostDeleteEmployeeChildInput = {
    id: number;
};

export default function EmployeeChildDeleteDialog({
    employeeChild,
    onSuccess,
    onCancel
}: EmployeeChildDeleteDialogProps) {
    const [, { errorMessage, isLoading: isDeleting, sendRequest: deleteEmployeeChild }] = useFetch<
        PostDeleteEmployeeChildPayload,
        PostDeleteEmployeeChildInput
    >('/deleteChild', {
        reqData: { id: employeeChild.id },
        manuallyTriggerRequest: true,
        onSuccess: () => onSuccess()
    });

    const { t } = useTranslation();

    return (
        <ConfirmDialog
            open
            severity="warning"
            title={t('actions.confirmDeletion')}
            confirmText={t('actions.delete')}
            isRunningConfirmOperation={isDeleting}
            onCancel={onCancel}
            onConfirm={deleteEmployeeChild}
            errorMessage={errorMessage}
        />
    );
}
