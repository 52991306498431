import { useEffect, useMemo } from 'react';
import InlineLoadingSpinner from 'components/loading/InlineLoadingSpinner';
import ErrorMessage from 'components/error/ErrorMessage';
import { Grid, Box, Theme, useMediaQuery } from '@mui/material';
import useQuery from 'hooks/useQuery';
import { EmployeeChildrenProvider, useEmployeeChildren } from '../providers';
import EmployeeChildrenHeader from './EmployeeChildrenHeader';
import EmployeeChildrenList from './EmployeeChildrenList';
import EmployeeChildDetails from './EmployeeChildDetails';

type EmployeeChildrenMainProps = {
    setFullscreen: (value: boolean) => void;
};

function EmployeeChildrenMain({ setFullscreen }: EmployeeChildrenMainProps) {
    const childId = useQuery().get('childId');

    const { employeeChildren, isLoadingFirstTime, errorMessage } = useEmployeeChildren();

    const currentlySelectedChild = useMemo(
        () => employeeChildren?.find((d) => d.id.toString() === childId) ?? null,
        [childId, employeeChildren]
    );

    const isSlimViewport = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
    const showHeaderAndList = !isSlimViewport || !currentlySelectedChild;

    useEffect(() => {
        setFullscreen(!showHeaderAndList);
    }, [showHeaderAndList, setFullscreen]);

    if (isLoadingFirstTime) {
        return <InlineLoadingSpinner />;
    }

    if (errorMessage && errorMessage.length > 0) {
        <ErrorMessage message={errorMessage} sx={{ m: 2 }} />;
    }

    return (
        <Box sx={{ mt: 1, display: 'flex', flexDirection: 'column', gap: 3 }}>
            {showHeaderAndList && <EmployeeChildrenHeader />}
            <Grid container columnSpacing={2}>
                {showHeaderAndList && (
                    <Grid
                        item
                        xs
                        sx={{
                            display: { xs: currentlySelectedChild ? 'none' : 'block', md: 'block' }
                        }}
                    >
                        <EmployeeChildrenList
                            employeeChildren={employeeChildren}
                            currentlySelectedChild={currentlySelectedChild}
                        />
                    </Grid>
                )}
                {currentlySelectedChild && (
                    <Grid item xs>
                        <EmployeeChildDetails employeeChild={currentlySelectedChild} />
                    </Grid>
                )}
            </Grid>
        </Box>
    );
}

export default function EmployeeChildrenMainWrapper(props: EmployeeChildrenMainProps) {
    return (
        <EmployeeChildrenProvider>
            <EmployeeChildrenMain {...props} />
        </EmployeeChildrenProvider>
    );
}
