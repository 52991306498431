import { EmployeeChildrenProvider } from 'features/profile/children';
import { AbsenceCodesProvider } from 'features/absence/providers';
import AbsenceRequestRegistration from './AbsenceRequestRegistration';
import { AbsenceRequestRegistrationProps } from './AbsenceRequestRegistration.types';

export default function AbsenceRequestRegistrationWrapper(props: AbsenceRequestRegistrationProps) {
    return (
        <EmployeeChildrenProvider>
            <AbsenceCodesProvider>
                <AbsenceRequestRegistration {...props} />
            </AbsenceCodesProvider>
        </EmployeeChildrenProvider>
    );
}
