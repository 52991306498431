import { useFormContext, Controller } from 'react-hook-form';
import useFieldNameGuard from 'hooks/useFieldNameGuard';
import DatePicker from 'components/Input/DatePicker';
import { DatePickerProps } from 'components/Input/DatePicker/DatePicker.types';

export type HFDatePickerProps = {
    name: string;
    label: string;
    fullWidth?: boolean;
} & Omit<DatePickerProps, 'onChange'>;

function HFDatePicker(datePickerProps: HFDatePickerProps) {
    const context = useFormContext();
    const { control } = context;

    const { name, helperText } = datePickerProps;
    useFieldNameGuard(name, context);

    return (
        <Controller
            control={control}
            name={name}
            render={({ field: { ref, ...field }, fieldState }) => (
                <DatePicker
                    {...datePickerProps}
                    {...field}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message || helperText}
                    inputRef={ref}
                />
            )}
        />
    );
}
HFDatePicker.defaultProps = {
    fullWidth: false
};

export default HFDatePicker;
