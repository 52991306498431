import { Box, Tab, Tabs, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import DutyFilterMain from 'features/profile/dutyFilter/DutyFilterMain';
import { useFeatureAccess } from 'features/misc/employeeSettings';
import { useNavigate } from 'react-router-dom';
import useQuery from 'hooks/useQuery';
import { camelCase, kebabCase } from 'lodash';
import { useState } from 'react';
import ProfileMain from './ProfileMain';
import { EmployeeChildrenMain } from '../../children/components';

const tabNames = ['main', 'dutyFilter', 'employeeChildren'] as const;
type TabName = typeof tabNames[number];

function getProfileTabId(name: TabName) {
    return `profile-tab-${name}`;
}

function getProfileTabPanelId(name: TabName) {
    return `profile-tabpanel-${name}`;
}

function isValidTabName(tabName: string | undefined): tabName is TabName {
    return Boolean(tabName && tabNames.includes(tabName as TabName));
}

interface TabPanelProps {
    children: React.ReactNode;
    panelsTabName: TabName;
    selectedTabName: TabName;
}

// In time we'lll have TabContext and TabList available in MUI, but for now we'll use CustomTabPanel and getTabA11yProps
// When that time comes we should also reimplement the tabs found in Balances.tsx
function CustomTabPanel(props: TabPanelProps) {
    const { children, selectedTabName, panelsTabName, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={panelsTabName !== selectedTabName}
            id={getProfileTabPanelId(panelsTabName)}
            aria-labelledby={getProfileTabId(panelsTabName)}
            {...other}
        >
            {panelsTabName === selectedTabName && <Box sx={{ p: 1 }}>{children}</Box>}
        </div>
    );
}

function getTabA11yProps(tabName: TabName) {
    return {
        id: getProfileTabId(tabName),
        'aria-controls': getProfileTabPanelId(tabName)
    };
}

export default function ProfileTabs() {
    const { t } = useTranslation();

    const { access, isLoadingFirstTime } = useFeatureAccess();

    const hasPlan = Boolean(access?.plan);
    const hasEmployeeChildrenAccess = Boolean(access?.employeeChildren);

    const accessibleTabs = tabNames.filter(
        (tabName) =>
            tabName === 'main' ||
            (tabName === 'employeeChildren' && hasEmployeeChildrenAccess) ||
            (tabName === 'dutyFilter' && hasPlan)
    );

    const kebabCasedTabNameFromParams = useQuery().get('tab') || '';
    const tabNameFromParams = camelCase(kebabCasedTabNameFromParams);
    const selectedTabName =
        isValidTabName(tabNameFromParams) && accessibleTabs.includes(tabNameFromParams)
            ? tabNameFromParams
            : 'main';

    const navigate = useNavigate();

    const [hasFullScreenTakeover, setHasFullScreenTakeover] = useState(false);

    if (isLoadingFirstTime) {
        return null;
    }

    return (
        <>
            {accessibleTabs.length > 1 && !hasFullScreenTakeover ? (
                <Box
                    sx={{
                        borderBottom: 1,
                        borderColor: 'divider',
                        display: hasFullScreenTakeover ? 'none' : 'block'
                    }}
                >
                    <Tabs
                        value={selectedTabName}
                        onChange={(event, targetTabName: TabName) =>
                            navigate(`/profile?tab=${kebabCase(targetTabName)}`)
                        }
                        variant="scrollable"
                        scrollButtons="auto"
                        allowScrollButtonsMobile
                    >
                        <Tab
                            label={t('profile.tabBar.main')}
                            value="main"
                            {...getTabA11yProps('main')}
                        />
                        {accessibleTabs.includes('employeeChildren') && (
                            <Tab
                                label={t('profile.tabBar.employeeChildren')}
                                value="employeeChildren"
                                {...getTabA11yProps('employeeChildren')}
                            />
                        )}
                        {accessibleTabs.includes('dutyFilter') && (
                            <Tab
                                label={t('profile.tabBar.dutyFilter')}
                                value="dutyFilter"
                                {...getTabA11yProps('dutyFilter')}
                            />
                        )}
                    </Tabs>
                </Box>
            ) : (
                <Typography
                    id="absence-request-overview-header"
                    component="h1"
                    variant="h5"
                    gutterBottom
                    sx={{
                        textTransform: 'uppercase'
                    }}
                >
                    {t('profile.tabBar.main')}
                </Typography>
            )}
            <CustomTabPanel panelsTabName="main" selectedTabName={selectedTabName}>
                <ProfileMain />
            </CustomTabPanel>
            {accessibleTabs.includes('employeeChildren') && (
                <CustomTabPanel panelsTabName="employeeChildren" selectedTabName={selectedTabName}>
                    <EmployeeChildrenMain setFullscreen={setHasFullScreenTakeover} />
                </CustomTabPanel>
            )}
            {accessibleTabs.includes('dutyFilter') && (
                <CustomTabPanel panelsTabName="dutyFilter" selectedTabName={selectedTabName}>
                    <DutyFilterMain />
                </CustomTabPanel>
            )}
        </>
    );
}
