import { EmployeeChild } from 'models/EmployeeChild';
import EmployeeChildRegistrationView, {
    EmployeeChildRegistrationViewProps
} from './EmployeeChildRegistrationView';
import useEmployeeChildRegistrationForm from './useEmployeeChildRegistrationForm';

interface EmployeeChildRegistrationProps
    extends Omit<EmployeeChildRegistrationViewProps, 'formProps'> {
    onSuccess: VoidFunction;
    employeeChild?: EmployeeChild;
}

export default function EmployeeChildRegistration({
    onSuccess,
    onCancel,
    employeeChild,
    ...viewProps
}: EmployeeChildRegistrationProps) {
    const formProps = useEmployeeChildRegistrationForm(employeeChild, onSuccess);

    return (
        <EmployeeChildRegistrationView
            {...viewProps}
            employeeChild={employeeChild}
            formProps={formProps}
            onCancel={onCancel}
        />
    );
}
EmployeeChildRegistration.defaultProps = {
    employeeChild: undefined
};
