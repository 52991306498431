import { dayjs } from 'appI18n';
import { t } from 'i18next';
import { useMemo } from 'react';
import { DateISOString } from 'utils/datetime';

export default function useRelativeAge(originDateString: DateISOString) {
    return useMemo(() => {
        const monthsSinceBirth = dayjs().diff(originDateString, 'months');

        if (dayjs().diff(originDateString) < 0) {
            // No need for support of future dates yet
            return '';
        }
        if (monthsSinceBirth < 1) {
            const daysSinceBirth = dayjs().diff(originDateString, 'days');
            return `${daysSinceBirth} ${t('dateTime.day', { count: daysSinceBirth })}`;
        }
        if (monthsSinceBirth < 12) {
            return `${monthsSinceBirth} ${t('dateTime.month', { count: monthsSinceBirth })}`;
        }

        const yearsSinceBirth = dayjs().diff(originDateString, 'years');
        return `${yearsSinceBirth} ${t('dateTime.year', {
            count: yearsSinceBirth
        })}`;
    }, [originDateString]);
}
