import { Divider, List } from '@mui/material';
import { EmployeeChild } from 'models/EmployeeChild';
import { Fragment } from 'react';
import EmployeeChildrenListItem from './EmployeeChildrenListItem';

type EmployeeChildrenListProps = {
    employeeChildren: Array<EmployeeChild>;
    currentlySelectedChild: EmployeeChild | null;
};

export default function EmployeeChildrenList({
    employeeChildren,
    currentlySelectedChild
}: EmployeeChildrenListProps) {
    return (
        <List disablePadding sx={(theme) => ({ backgroundColor: theme.palette.background.paper })}>
            {employeeChildren.map((child) => (
                <Fragment key={child.id}>
                    <EmployeeChildrenListItem
                        child={child}
                        isSelected={currentlySelectedChild?.id === child.id}
                    />
                    <Divider />
                </Fragment>
            ))}
        </List>
    );
}
