import { useMemo } from 'react';
import useMyCapitechClientSettings from 'hooks/useMyCapitechClientSettings';
import useEmployeeRegistrationInformation from '../useEmployeeRegistrationInformation';
import { UseFeatureAccessResult } from './useFeatureAccess.types';

export default function useFeatureAccess(): UseFeatureAccessResult {
    const { isLoading, employeeRegistrationInformation } = useEmployeeRegistrationInformation('');

    const {
        hasAbsenceRegistration,
        hasSimployerAbsence,
        hasCapitechTid,
        hasCapitechPlan,
        hasCapitechFlowPlan,
        hasFlexitime
    } = employeeRegistrationInformation ?? {};

    const { myCapitechClientSettings } = useMyCapitechClientSettings() || {};
    const { isChildRegistrationEnabled } = myCapitechClientSettings || {};

    const access = useMemo(
        (): UseFeatureAccessResult['access'] => ({
            absence: hasAbsenceRegistration ?? null,
            simployerAbsence: hasSimployerAbsence ?? null,
            time: hasCapitechTid ?? null,
            plan: (hasCapitechFlowPlan || hasCapitechPlan) ?? null,
            flexitime: hasFlexitime ?? null,
            employeeChildren: isChildRegistrationEnabled ?? null
        }),
        [
            hasAbsenceRegistration,
            hasSimployerAbsence,
            hasCapitechTid,
            hasCapitechFlowPlan,
            hasCapitechPlan,
            hasFlexitime,
            isChildRegistrationEnabled
        ]
    );

    return {
        isLoading,
        isLoadingFirstTime: !employeeRegistrationInformation,
        access
    };
}
