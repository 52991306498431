import HFFormProvider from 'ctx/HFFormProvider';
import { UseFormWithApiIntegrationReturn } from 'hooks/useFormWithApiIntegration';
import { useTranslation } from 'react-i18next';
import {
    Alert,
    AlertProps,
    Box,
    BoxProps,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Stack
} from '@mui/material';
import HFHidden from 'components/hookForm/HFHidden';
import HFCheckbox from 'components/hookForm/HFCheckbox';
import HFTextField from 'components/hookForm/HFTextField';
import ActionButton from 'components/button/ActionButton';
import HFTimeInput from 'components/hookForm/HFTimeInput';
import HFDatePicker from 'components/hookForm/HFDatePicker';
import ErrorMessage from 'components/error/ErrorMessage';
import { getTranslatedOrFallback } from 'utils/translation';
import {
    DimensionInputList,
    DimensionInputRules,
    DimensionOptionsByDimension
} from 'features/misc/dimensionInput';
import {
    DimensionValuesByDimension,
    getDimensionNameAsInputNameForAbsenceRegistration
} from 'utils/dimension';
import { InputOption } from 'components/Input/Dropdown/InputOption';
import HFDropdown, { HFDropdownProps } from 'components/hookForm/HFDropdown';
import DialogSpinner from 'components/dialog/DialogSpinner';
import HFDateRangePicker from 'components/hookForm/HFDateRangePicker';
import { DimensionName } from 'models/TimeRegistrationModels';
import { useFeatureAccess } from 'features/misc/employeeSettings';
import { useAbsenceCodes } from 'features/absence/providers';
import { useEmployeeChildren } from 'features/profile/children';
import { useCallback, useEffect, useState } from 'react';
import MarkdownLinkRenderer from 'components/MarkdownLinkRenderer';
import { AbsenceCodeCertificationType } from 'models/AbsenceCode';
import { PostAbsenceRequestPayload } from './hooks/useAbsenceRequestForm';

interface AbsenceRegistrationViewProps {
    formProps: UseFormWithApiIntegrationReturn<PostAbsenceRequestPayload>;
    dimensionInputRules: DimensionInputRules;
    dimensionOptionsSet: DimensionOptionsByDimension;
    dimensionNamesLoading: Array<DimensionName>;
    initDimensionValuesByDimensionName: DimensionValuesByDimension;
    onCancel: VoidFunction;
    sx?: BoxProps['sx'];
    isLoading: boolean;
    absenceCodeOptions: Array<InputOption>;
    isLoadingAbsenceCodeOptions: boolean;
}

function MissingEmployeeChildrenWarning({ severity }: Required<Pick<AlertProps, 'severity'>>) {
    const { t } = useTranslation();
    return (
        <Alert
            severity={severity}
            sx={{
                borderWidth: '1px',
                borderColor: `${severity}.main`,
                borderStyle: 'solid'
            }}
        >
            <MarkdownLinkRenderer
                text={t('absenceRequestRegistration.warnings.registerChildrenInProfile', {
                    employeeChildrenUrl: '/profile?tab=employee-children'
                })}
            />
        </Alert>
    );
}

export default function AbsenceRegistrationView({
    formProps,
    dimensionInputRules,
    dimensionOptionsSet,
    dimensionNamesLoading,
    initDimensionValuesByDimensionName,
    onCancel,
    absenceCodeOptions,
    sx,
    isLoading,
    isLoadingAbsenceCodeOptions
}: AbsenceRegistrationViewProps) {
    const { t } = useTranslation();
    const [isShortTermAbsence, absenceId] = formProps.watch(['isShortTermAbsence', 'absenceId']);

    // Absence code might require employee children to be registered if feature is enabled
    const selectedAbsenceCode = formProps.watch('absenceCode');
    const [warnMissingEmployeeChildren, setWarnMissingEmployeeChildren] = useState(false);
    const {
        access: { employeeChildren: isEmployeeChildrenAccessible }
    } = useFeatureAccess();
    const { employeeChildren, isLoadingFirstTime: isLoadingEmployeeChildrenFirstTime } =
        useEmployeeChildren();
    const { absenceCodes } = useAbsenceCodes();
    const isMissingEmployeeChildren = Boolean(
        isEmployeeChildrenAccessible &&
            !isLoadingEmployeeChildrenFirstTime &&
            employeeChildren.length === 0
    );

    useEffect(() => {
        if (!selectedAbsenceCode) {
            return;
        }

        const isEmployeeChildRequired =
            absenceCodes.find((absenceCode) => absenceCode.absenceCode === selectedAbsenceCode)
                ?.certificationType === AbsenceCodeCertificationType.SelfCertifiedChildSickness;

        setWarnMissingEmployeeChildren(isEmployeeChildRequired && isMissingEmployeeChildren);
    }, [selectedAbsenceCode, isMissingEmployeeChildren, absenceCodes]);

    const renderChildrenMissingIfAppropriate = useCallback<
        NonNullable<HFDropdownProps['renderCustomValidationFeedback']>
    >(
        (_errorMessage, fieldError) => {
            const hasMissingEmployeeChildrenError = fieldError?.type === 'custom';
            const showMessage = hasMissingEmployeeChildrenError || warnMissingEmployeeChildren;

            if (showMessage) {
                const severity = hasMissingEmployeeChildrenError ? 'error' : 'info';
                return <MissingEmployeeChildrenWarning severity={severity} />;
            }
            return undefined;
        },
        [warnMissingEmployeeChildren]
    );

    if (isLoading) {
        return <DialogSpinner />;
    }

    return (
        <Box sx={sx}>
            <HFFormProvider formProps={formProps}>
                <DialogTitle>
                    {t(
                        absenceId
                            ? 'actions.registerAbsenceChangeRequest'
                            : 'actions.registerAbsenceRequest'
                    )}
                </DialogTitle>
                <DialogContent>
                    {/* Drop down label gets cut off unless there's some space above */}
                    <Grid container spacing={2} sx={{ paddingTop: '5px' }}>
                        <Grid item xs={12}>
                            <HFDropdown
                                name="absenceCode"
                                enterKeyHint="next"
                                label={t('absenceRequestRegistration.absenceCode')}
                                fullWidth
                                required
                                options={absenceCodeOptions}
                                isLoadingOptions={isLoadingAbsenceCodeOptions}
                                renderCustomValidationFeedback={renderChildrenMissingIfAppropriate}
                            />
                            <HFHidden name="departmentId" />
                        </Grid>

                        <Grid
                            item
                            xs={12}
                            sx={{
                                marginBottom: '5px' // Added to add symmetry between elements. Floating label to next input, which is up top due to date being set at init, makes it seem like there's less space below the checkbox than above.
                            }}
                        >
                            <HFCheckbox
                                name="isShortTermAbsence"
                                label={t('absenceRequestRegistration.shortAbsence')}
                            />
                        </Grid>

                        {isShortTermAbsence ? (
                            <>
                                <Grid item xs={12}>
                                    <HFDatePicker
                                        name="fromDate"
                                        label={t('absenceRequestRegistration.fromDate')}
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Stack direction="row" spacing={2}>
                                        <HFTimeInput
                                            name="fromTime"
                                            label={t('absenceRequestRegistration.fromTime')}
                                            fullWidth
                                            required
                                        />
                                        <HFTimeInput
                                            name="toTime"
                                            label={t('absenceRequestRegistration.toTime')}
                                            fullWidth
                                            required
                                        />
                                    </Stack>
                                </Grid>
                            </>
                        ) : (
                            <Grid item xs={12}>
                                <HFDateRangePicker
                                    sx={(theme) => ({
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: theme.spacing(2)
                                    })}
                                    fromPickerProps={{
                                        name: 'fromDate',
                                        label: t('absenceRequestRegistration.fromDate'),
                                        fullWidth: true,
                                        required: true
                                    }}
                                    toPickerProps={{
                                        name: 'toDate',
                                        label: t('absenceRequestRegistration.toDate'),
                                        fullWidth: true,
                                        required: true
                                    }}
                                />
                            </Grid>
                        )}

                        {/*
                        The proper way to do this would be for the DimensionInputList to offer wrapper&wrapperProps
                        and provide { wrapper: Grid, wrapperProps: { item: true, xs:12 } } as props.

                        There result would make no visual difference to the end user though, so dropped implementing it for now.
                        */}
                        <Grid item xs={12}>
                            <Stack spacing={2}>
                                <DimensionInputList
                                    inputRules={dimensionInputRules}
                                    transformInputName={
                                        getDimensionNameAsInputNameForAbsenceRegistration
                                    }
                                    dimensionOptionsSet={dimensionOptionsSet}
                                    dimensionNamesLoading={dimensionNamesLoading}
                                    initValuesByDimensionName={initDimensionValuesByDimensionName}
                                />
                            </Stack>
                        </Grid>

                        <Grid item xs={12}>
                            <HFTextField
                                label={t('absenceRequestRegistration.message')}
                                name="text"
                                enterKeyHint="enter"
                                multiline
                                fullWidth
                                maxLength={500} // T820.fraverFritekst
                                rows={4}
                            />
                        </Grid>

                        {formProps.displayErrorMessage && (
                            <Grid item xs={12}>
                                <ErrorMessage
                                    message={getTranslatedOrFallback(
                                        formProps.displayErrorMessage,
                                        'absenceRequestRegistration.errors.genericFailedToCreate'
                                    )}
                                />
                            </Grid>
                        )}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <ActionButton type="button" variant="outlined" onClick={onCancel}>
                        {t('actions.cancel')}
                    </ActionButton>
                    <ActionButton isloading={formProps.formState.isSubmitting}>
                        {t('actions.save')}
                    </ActionButton>
                </DialogActions>
            </HFFormProvider>
        </Box>
    );
}
AbsenceRegistrationView.defaultProps = {
    sx: undefined
};
