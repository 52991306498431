import { createContext, PropsWithChildren, useMemo } from 'react';
import { useFetchArray } from 'hooks/useFetch';
import { AbsenceCode } from 'models/AbsenceCode';
import { AbsenceCodesState } from './AbsenceCodesProvider.types';

export const initialState: AbsenceCodesState = {
    absenceCodes: [],
    isLoading: false,
    isLoadingFirstTime: false,
    errorMessage: undefined
};

export const AbsenceCodesContext = createContext<AbsenceCodesState>(initialState);

export default function AbsenceCodesProvider({ children }: PropsWithChildren) {
    const [absenceCodes, { isLoading, isLoadingFirstTime, errorMessage }] =
        useFetchArray<AbsenceCode>('getAbsenceCodes');

    const value = useMemo(
        (): AbsenceCodesState => ({ absenceCodes, isLoading, isLoadingFirstTime, errorMessage }),
        [absenceCodes, isLoading, isLoadingFirstTime, errorMessage]
    );

    return <AbsenceCodesContext.Provider value={value}>{children}</AbsenceCodesContext.Provider>;
}
