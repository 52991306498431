import { KeyValueListItem } from 'components/KeyValueList/KeyValueList';
import { EmployeeChild, genderFromCode } from 'models/EmployeeChild';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useReadableDate from 'hooks/useReadableDate';
import {
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Divider,
    IconButton,
    List,
    ListItem,
    ListItemText,
    useTheme
} from '@mui/material';
import { Link } from 'react-router-dom';
import { Clear, Close, Edit } from '@mui/icons-material';
import ActionButton from 'components/button/ActionButton';
import { useDialog } from 'ctx/DialogCtx';
import DialogWithCloseBtn from 'components/dialog/DialogWithCloseBtn';
import useRelativeAge from './useRelativeAge';
import EmployeeChildAvatar from './EmployeeChildAvatar';
import { useEmployeeChildren } from '../providers';
import EmployeeChildRegistration from './EmployeeChildRegistration';
import EmployeeChildDeleteDialog from './EmployeeChildDeleteDialog';

type EmployeeChildrenDetailsProps = {
    employeeChild: EmployeeChild;
};

export default function EmployeeChildDetails({ employeeChild }: EmployeeChildrenDetailsProps) {
    const { t } = useTranslation();
    const relativeAge = useRelativeAge(employeeChild.dateOfBirth);
    const dateOfBirth = useReadableDate(employeeChild.dateOfBirth);

    const { showDialog } = useDialog();
    const { refreshEmployeeChildren } = useEmployeeChildren();

    const handleEditClick = useCallback(() => {
        showDialog((onClose) => (
            <DialogWithCloseBtn onClose={onClose} open>
                <EmployeeChildRegistration
                    employeeChild={employeeChild}
                    onCancel={onClose}
                    onSuccess={() => {
                        refreshEmployeeChildren();
                        onClose();
                    }}
                />
            </DialogWithCloseBtn>
        ));
    }, [employeeChild, refreshEmployeeChildren, showDialog]);

    const handleDeleteClick = useCallback(() => {
        showDialog((onClose) => (
            <EmployeeChildDeleteDialog
                employeeChild={employeeChild}
                onCancel={onClose}
                onSuccess={() => {
                    refreshEmployeeChildren();
                    onClose();
                }}
            />
        ));
    }, [employeeChild, refreshEmployeeChildren, showDialog]);

    const employeeChildDataEntries: Array<KeyValueListItem> = useMemo(() => {
        if (!employeeChild) return [];

        const unfilteredItems: Array<KeyValueListItem | undefined> = [
            {
                key: t('profile.employeeChildren.dateOfBirth'),
                value: dateOfBirth
            },
            {
                key: t('profile.employeeChildren.gender'),
                value: t(
                    `profile.employeeChildren.genderOptions.${genderFromCode[employeeChild.gender]}`
                )
            },
            {
                key: t('profile.employeeChildren.livesWithMe'),
                value: employeeChild.livesWithMe ? t('yes') : t('no')
            },
            {
                key: t('profile.employeeChildren.soleCustody'),
                value: employeeChild.soleCustody ? t('yes') : t('no')
            },
            {
                key: t('profile.employeeChildren.isChronicallyIll'),
                value: employeeChild.isChronicallyIll ? t('yes') : t('no')
            },
            {
                key: t('profile.employeeChildren.isStepChild'),
                value: employeeChild.isStepChild ? t('yes') : t('no')
            }
        ];

        return unfilteredItems.filter(
            (item): item is KeyValueListItem =>
                item !== null && item !== undefined && item.value !== ''
        );
    }, [employeeChild, dateOfBirth, t]);

    const theme = useTheme();

    return (
        <Card>
            <CardHeader
                title={employeeChild.name}
                subheader={relativeAge}
                avatar={<EmployeeChildAvatar />}
                action={
                    <IconButton
                        aria-label={t('actions.close')}
                        component={Link}
                        to="/profile?tab=employee-children"
                    >
                        <Close />
                    </IconButton>
                }
            />
            <Divider variant="middle" />
            <CardContent>
                <List>
                    {employeeChildDataEntries.map((item) => (
                        <ListItem key={item.key}>
                            <ListItemText
                                secondary={item.value}
                                secondaryTypographyProps={{
                                    variant: 'body1',
                                    fontWeight: theme.typography.fontWeightBold,
                                    fontFamily: 'GT-Walsheim-Bold',
                                    color: theme.palette.text.primary
                                }}
                                primary={item.key}
                                primaryTypographyProps={{
                                    variant: 'subtitle2'
                                }}
                            />
                        </ListItem>
                    ))}
                </List>
            </CardContent>
            <CardActions>
                <ActionButton onClick={handleEditClick} variant="outlined" startIcon={<Edit />}>
                    {t('actions.edit')}
                </ActionButton>
                <ActionButton onClick={handleDeleteClick} variant="outlined" startIcon={<Clear />}>
                    {t('actions.remove')}
                </ActionButton>
            </CardActions>
        </Card>
    );
}
