import { Grid, Theme, Typography, useMediaQuery } from '@mui/material';
import ActionButton from 'components/button/ActionButton';
import DialogWithCloseBtn from 'components/dialog/DialogWithCloseBtn';
import { useDialog } from 'ctx/DialogCtx';
import { useTranslation } from 'react-i18next';
import EmployeeChildRegistration from './EmployeeChildRegistration';
import { useEmployeeChildren } from '../providers';

export default function EmployeeChildrenHeader() {
    const { t } = useTranslation();
    const { showDialog } = useDialog();
    const { refreshEmployeeChildren } = useEmployeeChildren();

    const isSlimViewport = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

    const handleRegisterChildClick = () => {
        showDialog((closeDialog) => (
            <DialogWithCloseBtn onClose={closeDialog} open>
                <EmployeeChildRegistration
                    sx={{ maxWidth: '400px' }}
                    onSuccess={() => {
                        refreshEmployeeChildren();
                        closeDialog();
                    }}
                    onCancel={closeDialog}
                />
            </DialogWithCloseBtn>
        ));
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={9} lg={10}>
                <Typography>{t('profile.employeeChildren.pageHelperText')}</Typography>
            </Grid>
            <Grid
                item
                xs={12}
                sm={3}
                lg={2}
                display="flex"
                flexDirection="column"
                alignItems={isSlimViewport ? 'center' : 'end'}
            >
                <ActionButton onClick={handleRegisterChildClick} variant="outlined">
                    {t('profile.employeeChildren.actions.registerChild')}
                </ActionButton>
            </Grid>
        </Grid>
    );
}
