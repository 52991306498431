import { mapValues } from 'lodash';
import { useMemo } from 'react';

export default function useMemoWithIsEqual<T extends object>(object: T): T {
    const stringifiedObject = JSON.stringify(object, (k, v) =>
        v === undefined ? '@@UNDEFINED_REPLACEMENT' : v
    );
    return useMemo(
        () =>
            mapValues(JSON.parse(stringifiedObject), (v) =>
                v === '@@UNDEFINED_REPLACEMENT' ? undefined : v
            ),
        [stringifiedObject]
    );
}
