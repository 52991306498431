import { Info } from '@mui/icons-material';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

type DutyFilterRequiredDialogProps = {
    open: boolean;
    onClose: VoidFunction;
    triggerContext: 'displayVacantDuties' | 'displayDutyWish' | 'registerDutyWish';
};

const translationKeyToMessageByTriggerContext: Record<
    DutyFilterRequiredDialogProps['triggerContext'],
    string
> = {
    displayVacantDuties: 'dutyFilterRequired.messages.vacantDutiesDisplay',
    displayDutyWish: 'dutyFilterRequired.messages.dutyWishDisplay',
    registerDutyWish: 'dutyFilterRequired.messages.dutyWishRegistration'
};

export default function DutyFilterRequiredDialog({
    open,
    onClose,
    triggerContext
}: DutyFilterRequiredDialogProps) {
    const { t } = useTranslation();

    return (
        <Dialog open={open} sx={{ textAlign: 'center' }} onClose={onClose}>
            <Typography
                variant="h1"
                component="div"
                aria-hidden="true"
                sx={{ marginTop: '30px', display: 'flex', justifyContent: 'center' }}
            >
                <Info color="info" fontSize="inherit" />
            </Typography>

            <DialogTitle>{t('dutyFilterRequired.title')}</DialogTitle>
            <DialogContent>
                <Typography>
                    {t(translationKeyToMessageByTriggerContext[triggerContext])}
                </Typography>
            </DialogContent>

            <DialogActions sx={{ display: 'flex', justifyContent: 'center', gap: 1 }}>
                <Button type="button" onClick={onClose} variant="outlined" autoFocus>
                    {t('actions.close')}
                </Button>

                <Button component={Link} to="/profile?tab=duty-filter">
                    {t('dutyFilterRequired.openFilter')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
