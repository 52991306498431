type Gender = 'M' | 'K' | 'O';

export type EmployeeChild = {
    id: number;
    name: string;
    dateOfBirth: string;
    age: number;
    gender: Gender;
    livesWithMe: boolean;
    soleCustody: boolean;
    isChronicallyIll: boolean;
    isStepChild: boolean;
    active: boolean;
};

export const genderFromCode: Record<Gender, string> = {
    M: 'male',
    K: 'female',
    O: 'other'
} as const;
