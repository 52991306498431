import { InputOption } from 'components/Input/Dropdown/InputOption';
import { useMemo } from 'react';
import { useAbsenceCodes } from 'features/absence/providers';

export default function useAbsenceCodeOptions() {
    const { absenceCodes, isLoading, errorMessage } = useAbsenceCodes();

    const absenceCodesAsOptions = useMemo(
        () =>
            absenceCodes.map<InputOption>((absenceCode) => ({
                label: absenceCode.description,
                value: absenceCode.absenceCode,
                isSelectable: true
            })),
        [absenceCodes]
    );
    return {
        options: absenceCodesAsOptions,
        isLoading,
        errorMessage
    };
}
