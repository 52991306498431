import {
    Card,
    CardProps,
    FormControl,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup,
    RadioGroupProps
} from '@mui/material';
import useFieldNameGuard from 'hooks/useFieldNameGuard';
import { Controller, useFormContext } from 'react-hook-form';

type RadioOption = {
    label: string;
    value: string;
};

type HFRadioGroupProps = {
    name: string;
    label: string;
    options: Array<RadioOption>;
    defaultValue?: RadioOption['value'];
    wrapperSx?: CardProps['sx'];
    row?: boolean;
} & Omit<RadioGroupProps, 'name'>;

export default function HFRadioGroup({
    name,
    label,
    options,
    wrapperSx,
    defaultValue,
    row
}: HFRadioGroupProps) {
    const context = useFormContext();
    const { control, formState } = context;
    const hasErrors = formState.submitCount > 0 && formState.errors[name]?.message !== undefined;

    useFieldNameGuard(name, context);

    return (
        <Card
            sx={{
                ...wrapperSx,
                ...(hasErrors && { borderColor: 'error.main', border: 1 })
            }}
        >
            <Controller
                name={name}
                control={control}
                render={({ field: { onChange, value } }) => (
                    <FormControl>
                        <FormLabel>{label}</FormLabel>
                        <RadioGroup
                            defaultValue={defaultValue}
                            onChange={onChange}
                            value={value}
                            row={row}
                        >
                            {options.map((option) => (
                                <FormControlLabel
                                    key={option.value}
                                    value={option.value}
                                    label={option.label}
                                    control={<Radio />}
                                />
                            ))}
                        </RadioGroup>
                    </FormControl>
                )}
            />
        </Card>
    );
}

HFRadioGroup.defaultProps = {
    defaultValue: undefined,
    wrapperSx: undefined,
    row: false
};
