import { useFeatureAccess } from 'features/misc/employeeSettings';
import { useFetchArray } from 'hooks/useFetch';
import { EmployeeChild } from 'models/EmployeeChild';
import { createContext, PropsWithChildren, useCallback, useMemo } from 'react';
import { EmployeeChildrenContextType } from './EmployeeChildrenProvider.types';

export const EmployeeChildrenContext = createContext<EmployeeChildrenContextType>(null!);

export default function EmployeeChildrenProvider({ children }: PropsWithChildren) {
    const { employeeChildren: isEmployeeChildrenAccessible } = useFeatureAccess().access;

    // Kicks off request if access is ok
    const [employeeChildren, { isLoading, isLoadingFirstTime, errorMessage, sendRequest }] =
        useFetchArray<EmployeeChild, {}>('/getChildren', {
            manuallyTriggerRequest: !isEmployeeChildrenAccessible
        });

    // Refresh callback that ensures provider stays silent if without employee children access
    const refreshEmployeeChildren = useCallback(
        () => (isEmployeeChildrenAccessible ? sendRequest() : Promise.resolve()),
        [isEmployeeChildrenAccessible, sendRequest]
    );

    const value = useMemo((): EmployeeChildrenContextType => {
        const sortedChildren = [...employeeChildren].sort((a, b) => {
            // First sort by age (date of birth)
            const ageComparison =
                new Date(b.dateOfBirth).getTime() - new Date(a.dateOfBirth).getTime();
            if (ageComparison !== 0) return ageComparison;

            // If ages are equal, sort by name
            return a.name.localeCompare(b.name);
        });

        return {
            employeeChildren: sortedChildren,
            refreshEmployeeChildren,
            isLoading,
            isLoadingFirstTime,
            errorMessage
        };
    }, [employeeChildren, errorMessage, isLoading, isLoadingFirstTime, refreshEmployeeChildren]);

    return (
        <EmployeeChildrenContext.Provider value={value}>
            {children}
        </EmployeeChildrenContext.Provider>
    );
}
