import { useContext } from 'react';
import { AbsenceCodesContext } from './AbsenceCodesProvider';
import { UseAbsenceCodesResult } from './useAbsenceCodes.types';

export default function useAbsenceCodes(): UseAbsenceCodesResult {
    const ctx = useContext(AbsenceCodesContext);

    if (!ctx) {
        throw new Error('useAbsenceCodes must be used within an AbsenceCodesProvider');
    }

    return ctx;
}
