import { useContext } from 'react';
import { EmployeeChildrenContext } from './EmployeeChildrenProvider';
import { UseEmployeeChildrenResult } from './useEmployeeChildren.types';

export default function useEmployeeChildren(): UseEmployeeChildrenResult {
    const ctx = useContext(EmployeeChildrenContext);
    if (!ctx) {
        throw new Error('useEmployeeChildren must be used within an EmployeeChildrenProvider');
    }

    const {
        employeeChildren,
        isLoading,
        isLoadingFirstTime,
        errorMessage,
        refreshEmployeeChildren
    } = ctx;

    return {
        employeeChildren,
        isLoading,
        isLoadingFirstTime,
        errorMessage,
        refreshEmployeeChildren
    };
}
