import { Box, BoxProps, DialogActions, DialogContent, DialogTitle, Stack } from '@mui/material';
import { UseFormWithApiIntegrationReturn } from 'hooks/useFormWithApiIntegration';
import { useTranslation } from 'react-i18next';
import HFFormProvider from 'ctx/HFFormProvider';
import HFTextField from 'components/hookForm/HFTextField';
import HFDatePicker from 'components/hookForm/HFDatePicker';
import ActionButton from 'components/button/ActionButton';
import HFHidden from 'components/hookForm/HFHidden';
import HFRadioGroup from 'components/hookForm/HFRadioGroup';
import HFCheckbox from 'components/hookForm/HFCheckbox';
import { EmployeeChild } from 'models/EmployeeChild';
import { PostEmployeeChildRegistrationPayload } from './useEmployeeChildRegistrationForm';
import useRelativeAge from '../useRelativeAge';

export interface EmployeeChildRegistrationViewProps {
    employeeChild?: EmployeeChild;
    formProps: UseFormWithApiIntegrationReturn<PostEmployeeChildRegistrationPayload>;
    onCancel: VoidFunction;
    sx?: BoxProps['sx'];
}

export default function EmployeeChildRegistrationView({
    employeeChild,
    formProps,
    sx,
    onCancel
}: EmployeeChildRegistrationViewProps) {
    const { t } = useTranslation();

    const birthDate = formProps.watch('birthDate') as string | undefined;
    const relativeAge = useRelativeAge(birthDate || '');
    const ageString =
        birthDate && relativeAge ? `${t('profile.employeeChildren.age')}: ${relativeAge}` : '';

    return (
        <Box sx={{ ...sx, width: '400px', maxWidth: '100%' }}>
            <HFFormProvider formProps={formProps}>
                <DialogTitle>
                    {t(
                        `profile.employeeChildren.actions.${
                            employeeChild ? 'editChild' : 'registerChild'
                        }`
                    )}
                </DialogTitle>
                <DialogContent>
                    {employeeChild && <HFHidden name="id" />}
                    <HFHidden name="active" />
                    <Stack direction="column" spacing={2} sx={{ paddingTop: '5px' }}>
                        <HFTextField
                            name="name"
                            label={t('profile.employeeChildren.name')}
                            fullWidth
                            required
                        />
                        <HFDatePicker
                            name="birthDate"
                            label={t('profile.employeeChildren.dateOfBirth')}
                            fullWidth
                            required
                            helperText={ageString}
                            disableFuture
                            openTo="year"
                        />
                        <HFRadioGroup
                            name="gender"
                            label={t('profile.employeeChildren.gender')}
                            options={[
                                {
                                    label: t('profile.employeeChildren.genderOptions.male'),
                                    value: 'M'
                                },
                                {
                                    label: t('profile.employeeChildren.genderOptions.female'),
                                    value: 'K'
                                },
                                {
                                    label: t('profile.employeeChildren.genderOptions.other'),
                                    value: 'O'
                                }
                            ]}
                            row
                        />
                        <Stack direction="column">
                            <HFCheckbox
                                name="livesWithMe"
                                label={t('profile.employeeChildren.inputVariations.livesWithMe')}
                                helperText={t('profile.employeeChildren.inputHelpText.livesWithMe')}
                                indentHelperText
                                keepHelperTextOnError
                            />
                            <HFCheckbox
                                name="soleCustody"
                                label={t('profile.employeeChildren.inputVariations.soleCustody')}
                                helperText={t('profile.employeeChildren.inputHelpText.soleCustody')}
                                indentHelperText
                                keepHelperTextOnError
                            />
                            <HFCheckbox
                                name="isChronicallyIll"
                                label={t(
                                    'profile.employeeChildren.inputVariations.isChronicallyIll'
                                )}
                                helperText={t(
                                    'profile.employeeChildren.inputHelpText.isChronicallyIll'
                                )}
                                indentHelperText
                                keepHelperTextOnError
                            />
                            <HFCheckbox
                                name="isStepChild"
                                label={t('profile.employeeChildren.inputVariations.isStepChild')}
                                helperText={t('profile.employeeChildren.inputHelpText.isStepChild')}
                                indentHelperText
                                keepHelperTextOnError
                            />
                        </Stack>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <ActionButton type="button" variant="outlined" onClick={onCancel}>
                        {t('actions.cancel')}
                    </ActionButton>
                    <ActionButton isloading={formProps.formState.isSubmitting}>
                        {t('actions.save')}
                    </ActionButton>
                </DialogActions>
            </HFFormProvider>
        </Box>
    );
}

EmployeeChildRegistrationView.defaultProps = {
    employeeChild: undefined,
    sx: undefined
};
